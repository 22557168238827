// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogs-computer-vision-js": () => import("./../../../src/pages/blogs/computer-vision.js" /* webpackChunkName: "component---src-pages-blogs-computer-vision-js" */),
  "component---src-pages-blogs-deep-learning-js": () => import("./../../../src/pages/blogs/deep-learning.js" /* webpackChunkName: "component---src-pages-blogs-deep-learning-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-blog-post-js": () => import("./../../../src/templates/blog-post/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-blog-post-js" */)
}

